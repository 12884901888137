import { Component, Input, OnInit, SimpleChanges, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterState } from '@angular/router';
import { Logger } from '@app/@shared';
import { AuthenticationService } from '@app/auth';
import { LoginComponent } from '@app/auth/login.component';
import { environment } from '@env/environment';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';

const log = new Logger('Login');
@UntilDestroy()
@Component({
  selector: 'app-reset-finish',
  templateUrl: './reset-finish.component.html',
  styleUrls: ['./reset-finish.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: ['prices', 'global', 'register', 'settings', 'model_realEstate', 'reset', 'password'],
    },
  ],
})
export class ResetFinishComponent implements OnInit {
  private authenticationService = inject(AuthenticationService);
  private formBuilder = inject(UntypedFormBuilder);
  private route = inject(ActivatedRoute);
  private dialog = inject(MatDialog);
  doNotMatch: any;
  error: any;
  success: any;
  account!: any;
  passwordForm!: UntypedFormGroup;
  passwordValue: any;
  strength!: number;
  _color!: string;
  @Input() externalError!: boolean;
  requestId!: string;
  resetAccount: any;
  confirmPassword: any;
  keyMissing: any;

  constructor() {
    this.requestId = this.route.snapshot.queryParams['key'] as string;
    console.log('this.requestId', this.requestId);
    // const state: RouterState = router.routerState;
    // console.log("state", state);
    this.passwordFormData();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if(changes['password']) {
    //   this.passwordToCheck(changes['password'].currentValue);
    // }
    // if (changes['externalError'] && changes['externalError'].firstChange) {
    //   this._color = Colors.primary;
    //   console.log("primary color", this._color);
    //   return;
    // }
    // if (changes['externalError'] && changes['externalError'].currentValue) {
    //   this._color = Colors.warn;
    //   return;
    // }
  }

  ngOnInit(): void {
    this.authenticationService
      .identity()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          if (value != null) {
            console.log('value of account', value);
            this.account = value;
          }
        },
        error: (error) => {
          console.error('error in rebuild addition', error);
        },
      });

    this.passwordValue = this.passwordForm.get('password')?.value;

    this.keyMissing = this.requestId === undefined ? this.requestId : '';
  }

  passwordFormData() {
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
    });
    // this.formcontrol.get('formcontrolfield')?.patchValuewhateverValue);
  }

  passwordToCheck(NewPass: any) {
    this.passwordValue = this.passwordForm.get('password')?.patchValue(NewPass.target.value);
  }

  // get color(): string {
  //   if (this.strength <= 20) {
  //     return Colors.warn;
  //   } else if (this.strength <= 80) {
  //     return Colors.accent;
  //   } else {
  //     return Colors.primary;
  //   }
  // }

  saveNewPassword() {
    let password = this.passwordForm.get('password')?.value;
    let confirmPassword = this.passwordForm.get('confirmPassword')?.value;
    console.log('savePass', this.requestId);

    if (password !== confirmPassword) {
      this.error = null;
      this.success = null;
      this.doNotMatch = 'ERROR';
    } else {
      this.doNotMatch = null;
      let checkDto = { key: this.requestId, newPassword: password };
      this.authenticationService
        .resetPasswordFinish(checkDto)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (value) => {
            console.log('value of account', value);
            this.error = null;
            this.success = 'OK';
            // this.account = value;
          },
          error: (error) => {
            this.success = null;
            this.error = 'ERROR';
            console.error('error in change password', error);
          },
        });
    }
  }

  login(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '600px',
      // data: { name: this.name, color: this.color }

      panelClass: 'custom-modalbox',
      closeOnNavigation: true,
    });
  }
}
