import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPasswordComponent } from './reset-password.component';
import { ResetPasswordRoutingModule } from './reset-password-routing.module';
import { TranslocoModule } from '@jsverse/transloco';
import { MaterialModule } from '@app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicImageModule } from '@app/dynamic-image/dynamic-image.module';
import { ResetFinishComponent } from './reset-finish/reset-finish.component';
import { PasswordStrengthMeterComponent } from 'angular-password-strength-meter';

@NgModule({
  declarations: [ResetPasswordComponent, ResetFinishComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ResetPasswordRoutingModule,
    DynamicImageModule,
    PasswordStrengthMeterComponent,
  ],
})
export class ResetPasswordModule {}
