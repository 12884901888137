<div class="container login-container reg-container" *transloco="let t" id="password_reset">
  <mat-card style="padding: 0 !important">
    <app-dynamic-image [title]="title"></app-dynamic-image>
    <div class="flexBoxOuter" style="margin-top: 30px">
      <div class="flexBoxInner">
        <mat-card-content>
          <mat-card class="login-box">
            <div class="alert kw-attention" [hidden]="success">
              <p>{{ t("reset.request.messages.info") }}</p>
            </div>
            <div
              class="alert kw-attention"
              data-translate="reset.request.messages.notfound"
              *ngIf="errorEmailNotExists"
              [innerHtml]="t('reset.request.messages.notfound')"
            ></div>
            <div class="alert kw-attention" *ngIf="success === 'OK'">
              <p>{{ t("reset.request.messages.success") }}</p>
            </div>
            <form (ngSubmit)="resetPassword()" [formGroup]="resetForm">
              <div class="login-fields flex-col">
                <mat-form-field [floatLabel]="'always'" class="example-full-width" appearance="outline">
                  <mat-label>{{ t("global.form.email") }}</mat-label>
                  <input
                    type="email"
                    matInput
                    formControlName="mail"
                    placeholder="{{ t('global.form.email.placeholder') }}"
                  />
                  <mat-error *ngIf="resetForm.hasError('mail') && !resetForm.hasError('required')">
                    {{ t("global.messages.validate.email.invalid") }}
                  </mat-error>
                  <mat-error *ngIf="resetForm.get('mail')?.hasError('required')">
                    {{ t("global.messages.validate.email.required") }}
                  </mat-error>
                  <mat-error *ngIf="resetForm.get('mail')?.hasError('minlength')">
                    {{ t("global.messages.validate.email.minlength") }}
                  </mat-error>
                  <mat-error *ngIf="resetForm.get('mail')?.hasError('maxlength')">
                    {{ t("global.messages.validate.email.maxlength") }}
                  </mat-error>
                </mat-form-field>
                <br />
                <button class="button2" mat-button type="submit">{{ t("reset.request.form.button") }}</button>
              </div>
            </form>
          </mat-card>
        </mat-card-content>
      </div>
    </div>
  </mat-card>
</div>
