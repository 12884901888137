<div class="container" *transloco="let t" id="password_finish">
  <mat-card style="padding: 0 !important; box-shadow: unset">
    <app-dynamic-image [mode]="'BOOTSTRAP'">
      <div class="bootstrap-container">
        <div style="margin-bottom: 25px">
          <h1 class="bannerTitle">
            {{ t("reset.finish.title") }}
          </h1>
        </div>
      </div>
    </app-dynamic-image>
    <div class="flex-row justify-center" style="margin-top: 30px">
      <mat-card-content class="mat-card-content" style="margin-top: 30px">
        <mat-card class="login-box">
          <div class="alert kw-attention" *ngIf="keyMissing" [innerHtml]="t('reset.finish.messages.keymissing')"></div>

          <div class="alert kw-attention" *ngIf="!success || !keyMissing">
            <span>{{ t("reset.finish.messages.info") }}</span>
          </div>

          <div class="alert kw-attention" *ngIf="error" [innerHtml]="t('reset.finish.messages.error')">
            <!-- <strong>An error has occurred!</strong> The password could not be changed. -->
          </div>

          <div class="alert kw-attention" *ngIf="success">
            <span [innerHtml]="t('reset.finish.messages.success')"></span>
            <a (click)="login()" style="cursor: pointer">{{ t("reset.finish.messages.success-one") }}</a>
            <span>{{ t("reset.finish.messages.success-two") }}</span>
          </div>

          <div class="alert kw-attention" *ngIf="doNotMatch" data-translate="global.messages.error.dontmatch">
            {{ t("global.messages.error.dontmatch") }}
          </div>

          <ng-container *ngIf="!keyMissing">
            <form
              id="settingsOrganisation"
              (ngSubmit)="saveNewPassword()"
              [formGroup]="passwordForm"
              style="width: 100%"
              *ngIf="!success"
            >
              <div class="password-fields flex-col" id="password-fields">
                <mat-form-field
                  appearance="outline"
                  floatLabel="always"
                  class="mat-input-size mat-password-field"
                  style="width: 100%"
                >
                  <mat-label>{{ t("global.form.newpassword") }}</mat-label>
                  <input
                    matInput
                    type="password"
                    class="form-control"
                    id="password"
                    formControlName="password"
                    #password
                    placeholder="{{ t('global.form.newpassword.placeholder') }}"
                  />
                </mat-form-field>
                <div
                  class="validation-box"
                  *ngIf="passwordForm.get('password')?.dirty && passwordForm.get('password')?.invalid"
                >
                  <p class="help-block" *ngIf="passwordForm.get('password')?.hasError('required')">
                    {{ t("global.messages.validate.newpassword.required") }}
                  </p>
                </div>
                <div class="validation-box" *ngIf="passwordForm.get('password')?.hasError('minlength')">
                  <p class="help-block" data-translate="">
                    {{ t("global.messages.validate.newpassword.minlength") }}
                  </p>
                </div>
                <div class="validation-box" *ngIf="passwordForm.get('password')?.hasError('maxlength')">
                  <p class="help-block" data-translate="">
                    {{ t("global.messages.validate.newpassword.maxlength") }}
                  </p>
                </div>
                <div class="strength-box">
                  <small data-translate="" style="margin-right: 10px">{{
                    t("global.messages.validate.newpassword.strength")
                  }}</small>
                  <password-strength-meter
                    style="width: 35%; vertical-align: middle; display: inline-flex"
                    #passwordComponent
                    [password]="password.value"
                    class="green"
                  ></password-strength-meter>
                </div>
                <mat-form-field appearance="outline" class="mat-input-size" floatLabel="always" style="width: 100%">
                  <mat-label>{{ t("global.form.confirmpassword") }}</mat-label>
                  <input
                    matInput
                    type="password"
                    class="form-control"
                    id="confirmPassword"
                    formControlName="confirmPassword"
                    placeholder="{{ t('global.form.confirmpassword.placeholder') }}"
                  />
                </mat-form-field>
                <div *ngIf="passwordForm.get('confirmPassword')?.dirty && passwordForm.get('confirmPassword')?.invalid">
                  <p class="help-block" *ngIf="passwordForm.get('confirmPassword')?.hasError('required')">
                    {{ t("global.messages.validate.newpassword.required") }}
                  </p>
                </div>
                <div *ngIf="passwordForm.get('confirmPassword')?.hasError('minlength')">
                  <p class="help-block" data-translate="">
                    {{ t("global.messages.validate.newpassword.minlength") }}
                  </p>
                </div>
                <div *ngIf="passwordForm.get('confirmPassword')?.hasError('maxlength')">
                  <p class="help-block" data-translate="">
                    {{ t("global.messages.validate.newpassword.maxlength") }}
                  </p>
                </div>
                <br />
                <button type="submit" color="primary" class="btn btn-elegant-orange" style="width: 190px">
                  {{ t("reset.finish.form.button") }}
                </button>
              </div>
            </form>
          </ng-container>
        </mat-card>
      </mat-card-content>
    </div>
  </mat-card>
</div>
